import localObjStore from "~/LocalObjStore";
import { getCsrfToken } from "~/utils/auth";
import { useQuery } from "react-query";
import { contractListOptions } from "~/contracts/components/contractListOptions";

async function save(params) {
  const response = await fetch(`/api/v1/saved-views/${params.uuid}/`, {
    method: "PUT",
    headers: {
      "X-CSRFToken": getCsrfToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });
  return await response.json();
}

function saveCache(uikey, view) {
  if (typeof view?.selectedcolumns == "string") {
    view.selectedcolumns = JSON.parse(view.selectedcolumns);
  }
  if (view?.search_params) {
    if (typeof view.search_params == "string") {
      try {
        view.search_params = JSON.parse(view.search_params);
      } catch (e) {
        view.search_params = [];
      }
    }
  }
  localObjStore.set(uikey + "dashboard_view", view);
}

function loadCache(uikey) {
  return localObjStore.get(uikey + "dashboard_view");
}

function clearCache(uikey) {
  localObjStore.remove(uikey + "dashboard_view");
}

function getDefaultedInfo(view) {
  return {
    viewTypeName:
      view?.view_name == "Upcoming" || view?.view_type == "uc"
        ? "upcoming"
        : "default",
    filters: view?.search_params?.filters || [],
    advancedSearches: view?.search_params?.search_terms,
    pageSettings: {
      itemsPerPage: view?.search_params?.itemsPerPage || DEFAULT_ITEMS_PER_PAGE,
      bigCurrentPage: view?.search_params?.bigCurrentPage || 1,
      sortby: view?.search_params?.sortby,
      sortby_subfield: view?.search_params?.sortby_subfield,
    },
  };
}

function getQueryParams(view) {
  const { viewTypeName, filters, advancedSearches, pageSettings } =
    getDefaultedInfo(view);

  return {
    count: 0,
    filter: filters,
    page: pageSettings.bigCurrentPage,
    pageSize: pageSettings.itemsPerPage,
    sortby: pageSettings.sortby,
    sortby_subfield: pageSettings.sortby_subfield,
    sparam: advancedSearches,
    view: viewTypeName,
  };
}

const CACHE_DURATION = 60 * 1000;

async function getCachedResults(queryClient, queryParams) {
  const options = cachedResultsQueryOptions(queryParams);
  return await queryClient.fetchQuery(options);
}

function cachedResultsQueryOptions(queryParams) {
  return {
    ...contractListOptions(queryParams),
    staleTime: CACHE_DURATION,
    cacheTime: CACHE_DURATION,
  };
}

export function useGetCachedResults(queryParams) {
  const { data } = useQuery({
    ...cachedResultsQueryOptions(queryParams),
    suspense: true,
  });
  return data;
}

export default {
  save,
  saveCache,
  loadCache,
  clearCache,
  getDefaultedInfo,
  getQueryParams,
  getCachedResults,
  useGetCachedResults,
};
