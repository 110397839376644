import React from "react";
import { createContext, useContext, useState } from "react";
import DashboardViews from "~/utils/dashboard-views";
import { DEFAULT_SEARCH_PARAMS } from "./utils/global";
import type { View } from "./core/contracts/dashboard-views";

type SetViewFn = (getNewView: (currentView: View) => View) => void;
type UpdateViewFn = (updates: Record<string, any>) => void;

type ViewContextType = [View, SetViewFn, UpdateViewFn];

export const ViewContext = createContext<ViewContextType | null>(null);

type ViewContextProviderProps = {
  children: React.ReactNode;
  organization: { id: string } | null;
  initialView?: View;
};

export function ViewContextProvider(props: ViewContextProviderProps) {
  const { initialView, organization } = props;

  const [view, _setView] = useState(
    initialView ?? getInitialView(organization),
  );

  const setView = (getNewView: any) =>
    _setView((oldView: any) => {
      const orgId = organization?.id;
      const newView =
        typeof getNewView === "function" ? getNewView(oldView) : getNewView;
      if (orgId) {
        DashboardViews.clearCache("undefined"); // removing a mistake, if it's there
        // Don't need to decode the organization ID before setting the cache
        // since it's not coming base64 encoded with the type from relay.
        DashboardViews.saveCache(orgId, newView);
      }
      return newView;
    });

  const updateView = (updates: Partial<View>) =>
    setView((oldView: View) => ({
      ...oldView,
      ...updates,
      search_params: {
        ...(oldView?.search_params ?? {}),
        ...(updates?.search_params ?? {}),
      },
    }));

  return (
    <ViewContext.Provider value={[view, setView, updateView]}>
      {props.children}
    </ViewContext.Provider>
  );
}

function getInitialView(organization: { id: string } | null) {
  const orgId = organization?.id;
  const ngView = orgId && DashboardViews.loadCache(orgId);

  return ngView
    ? {
        ...ngView,
        search_params: {
          ...(ngView?.search_params || DEFAULT_SEARCH_PARAMS),
          filters: ngView?.search_params?.filters ?? [],
          search_terms: ngView?.search_params?.search_terms ?? [],
        },
      }
    : null;
}

export function useViewContext() {
  const context = useContext(ViewContext);
  if (!context) {
    throw new Error("useViewContext must be used within a ViewContext");
  }

  return context;
}

export default ViewContext;
