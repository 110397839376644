import {
  Environment,
  Network,
  RecordSource,
  Store,
  Observable,
} from "relay-runtime";
import { getCsrfToken } from "~/utils/auth";
import { createClient } from "graphql-ws";
import { getCookie } from "~/utils/cookies";

class QueryError extends Error {
  constructor(message, graphqlErrors, ...params) {
    super(message, ...params);
    this.name = "QueryError";
    this.graphqlErrors = graphqlErrors;
  }
}
const client_endpoint_url = "ws://" + location.host + "/graphql";

const subscriptionsClient = createClient({
  url:
    location.protocol === "https:"
      ? "wss://" + location.host + "/graphql"
      : client_endpoint_url,
  connectionParams: () => {
    const session = getCookie("sessionid");
    if (!session) {
      return {};
    }
    return {
      Authorization: `Bearer ${session.token}`,
    };
  },
});

const subscribe = (operation, variables) => {
  return Observable.create((sink) => {
    return subscriptionsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      sink,
    );
  });
};

async function fetchQuery(operation, variables) {
  const response = await fetch("/graphql", {
    method: "POST",
    timeout: 600000, //10mins
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCsrfToken(),
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  });
  const query_result = await response.json();

  if (!query_result?.errors) {
    return query_result;
  }

  if (
    query_result.errors[0].message == "Not Logged In" &&
    window.location.pathname != "/login"
  ) {
    window.location =
      "/login?next=" +
      window.encodeURIComponent(
        window.location.pathname + window.location.search,
      );
    return;
  }
  throw new QueryError(query_result.errors[0].message, query_result.errors);
}

const environment = new Environment({
  network: Network.create(fetchQuery, subscribe),
  store: new Store(new RecordSource(), {
    gcReleaseBufferSize: 60,
    queryCacheExpirationTime: 5 * 60 * 1000,
  }),
});

export default environment;
